// ------ BRAND -------

// BRAND - PRIMARY
$color-brand-primary-dark: #2C4759;
$color-brand-primary-medium: #45708C;
$color-brand-primary-light: #88BCC6;
$color-brand-green: #153727;

// BRAND - SECONDARY
$color-brand-secondary-dark: #515151;
$color-brand-secondary-medium: #767676;
$color-brand-secondary-light: #BABFC7;

// ------ NEUTRAL -------

// NEUTRAL - BASE
$color-base-black: #000000;
$color-base-white: #FFFFFF;

// NEUTRAL - GREY
$color-grey-900: #1E2026;
$color-grey-800: #3D3D3D;
$color-grey-700: #5B5D61;
$color-grey-500: #A6A7AC;
$color-grey-400: #C1C3C7;
$color-grey-300: #D4D6DA;
$color-grey-200: #EAEBED;
$color-grey-100: #F5F6F6;

// ------ FEEDBACK -------

// FEEDBACK - SUCCESS
$color-feedback-success-base: #146C2E;
$color-feedback-success-light: #007B55;

// FEEDBACK - WARNING
$color-feedback-warning-base: #ffae00;
$color-feedback-warning-light: #fac85a;

// FEEDBACK - ERROR
$color-feedback-error-base: #AB2525;
$color-feedback-error-light: #CF2D2D;

// ------ ALIASES -------

// ALIASES - PLATAFORMA
$color-aliases-plataforma-border: #CCCCCC;
$color-aliases-plataforma-textdefault: #3F353D;
$color-aliases-plataforma-supporttext: #5C4F5A;
$color-aliases-plataforma-placeholder: #B8B4B7;
$color-aliases-plataforma-tooltip: #262025;
$color-input-label: #919EAB;